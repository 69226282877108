@use "../../styles/partials/mixins" as *;

.work {
  &__heading {
    padding: 2rem 0;
  }

  &__container {
    height: 100%;
    padding: .5rem 0;

   
  }

  &__site--heading {
    @include tablet {
      width: 50%;
    }
  }

  &__site {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;


    @include tablet {
      flex-direction: row;

    }
  }

  &__image {
    width: 80%;
    height: auto;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &:hover {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;
    }

    @include tablet {
      width: 80%;
      margin-bottom: 3rem;

    }
  }

  &__blag {
    padding: 0 2rem;
    text-align: left;
    margin-bottom: 1rem;

    @include tablet {
        width: 30%;
        font-size: 1rem;
    }

    @include desktop {
      font-size: 1.3rem;
    }

    &--link {
      text-decoration: none;
      font-weight: 700;
    }
  
    
  }
}
