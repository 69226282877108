@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: $fonts;

  &__heading {
    font-size: 3rem;
    padding: 2rem 0;

    @include tablet {
      font-size: 4rem;
    }
  }

  &__p {
    padding: 1rem;
  }

  &__image--container {
    height: 12rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;

    @include tablet {
      padding: 6rem 0;
      height: auto;
    }
  }

  &__image {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    background-image: url("../../assets/images/me.jpg");
    background-size: cover;

    @include tablet {
      width: 15rem;
      height: 15rem;
    }
  }

  &__about {
    height: 15rem;
    color: $fonts;
    background-color: $background;
    width: 100%;
  }

  &__name {
    font-size: 1.4rem;
    padding: 1.5rem;
  }

  &__about--p {
    font-size: 1rem;
    padding: 0.5rem 5rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      flex-direction: row;
    }

   
  }

  &__front {
    border-bottom: 1px solid black;
    width: 85%;
    padding: 4rem 1.5rem;

    @include tablet {
        border-right: 1px solid black;
        border-bottom: 0;
    }

  }

  &__back {
    width: 85%;
    padding: 4rem 1.5rem;
  }
}
