@use "../../styles/partials/colors" as *;
@use "../../styles/partials/fonts" as *;
@use "../../styles/partials/mixins" as *;

.blog {
    display: flex;
    flex-direction: column;
    align-items: center;


    &__blog {
        padding: 2rem 0 1.5rem 0;
    }

    &__card {
        margin: 1rem;
        width: 90%;
        border-radius: 4px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);

    
    
    }

    &__image {
        background-size:cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 20rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &__image1 {
        background-image: url("../../assets/images/portfolio/programming.jpg");

    }

    &__image2 {
        background-image: url("../../assets/images/portfolio/code.jpg");
    }

    &__image3 {
        background-image: url("../../assets/images/portfolio/frames.jpg");
    }

    &__image4 {
        background-image: url("../../assets/images/portfolio/amazon.webp");
    }

    &__content {
        padding: 1.5rem;
        text-align: left;
        line-height: 1.5;
        letter-spacing: .15rem;
    }

    &__heading {
        padding: .2rem 0;
    }

    &__blurb {
        opacity: 80%;
        padding: 1rem 0;
    }

    &__hidden {
        // padding: 1rem 0;
    }


    &__read {
        padding: .5rem 2rem;
        background-color: $background;
        color: #fff;
        border: 0;
        border-radius: 4px;
    }
}

h4 {
    padding-top: .2rem;
}