@use "../../styles/partials/mixins" as *;

.photo {

    &__heading {
        padding: 2rem 0;

       
    }

    &__container {
        padding: 1rem .7rem; 
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }


        &__image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 22rem;
            height: 22rem;

            @include tablet {
                width: 30rem;
                height: 30rem;
            }

            @include desktop {
             
                width: 46rem;
                height: 46rem;
                
            }
        }



   
} 




    

    .image1 {
        background-image: url("../../assets/images/personal/ruben.webp");
    }

    .image2 {
        background-image: url("../../assets/images/personal/emma.webp");
    }

    .image3 {
        background-image: url("../../assets/images/personal/Arrrrghh.webp")
    }

    .image4 {
        background-image: url("../../assets//images/personal/ball.webp");
    }

    .image5 {
        background-image: url("../../assets/images/personal/cambodia.webp");
    }

    .image6 {
        background-image: url("../../assets/images/personal/killingfields.jpg");
    }

    .image7 {
        background-image: url("../../assets/images/personal/Crankworks_8.jpg");
    }

    .image8 {
        background-image: url("../../assets/images/personal/wild.webp");
    }

    .image9 {
        background-image: url("../../assets/images/personal/seaway.JPG");
    }

    .image10 {
        background-image: url("../../assets/images/personal/Squamish.webp")
    }

    .image11 {
        background-image: url("../../assets/images/personal/vancouver.webp")
    }

    .image12 {
        background-image: url("../../assets/images/personal/works.webp");
    }