@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;


.nav {
    height: 3.5rem;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1%;
    position: relative;

    @include desktop {
        padding: 0 2%;
    }

}
    

    .list {
        display: none;
        margin: 12rem 0 0 -1rem;
        line-height: 1.5;
        width: 100%;
        position: absolute;
        flex-direction: column;
        background-color: $background;
        padding: 1.2rem 2rem;


        @include tablet {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: .5rem 1rem;
            width: 40%;
            background-color: #fff;
            position: relative;
            margin: 5rem 0;

        }

        @include desktop {
            width: 30%;
        }

        &__item {
            font-size: 1.5rem;
            list-style-type: none;
            text-align: left;
        }
    }

    .list.open {
        display: block;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        height: 2rem;
        line-height: 1;
        width: 2rem;
        cursor: pointer;
        padding: .2rem .7rem;
        margin: 15rem 0 0 -1;
        position: relative;
        cursor: pointer;

        @include tablet {
            display: none;
        }
    }
        
    span {
        border: 1px solid black;
        margin: .2rem;
    }
   

    .link {
        text-decoration: none;
    }

    .logo {
        padding: 0 .7rem;
    }



