@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.footer {
  background-color: $background;
  padding: 1rem;

  &__header {
    padding: 1.5rem 0;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include tablet {
              flex-direction: row;
        width: 90%;
        margin: auto;
    }

    @include desktop {
      width: 90%;
      margin: auto;
    }
  }

  &__socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 2rem;


    @include tablet {
        padding: 3rem;
        flex-direction: column;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__git {
    background-image: url("../../assets/icons/github.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }

  &__insta {
    background-image: url("../../assets/icons/instagram.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
  }
}

.form {
  width: 100%;

  &__name,
  &__email,
  &__message {
    width: 100%;
  }

  &__name--input,
  &__email--input,
  &__message--input {
    border-radius: 4px;
    font-family: "Nunito";
    resize: none;
    width: 95%;
    padding: 0.5rem;
    margin: 0.2rem 0;
    border: 0;
  }

  &__button {
    height: 2rem;
    width: 98%;
    border-radius: 4px;
    appearance: button;
    backface-visibility: hidden;
    background-color: $fonts;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
      rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
      Ubuntu, sans-serif;
    font-size: 100%;
    height: 44px;
    line-height: 1.15;
    margin: 12px 0 0;
    outline: none;
    overflow: hidden;
    padding: 0 25px;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  @include desktop {
    width: 50%;
  }
}
